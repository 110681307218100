<template>
  <b-modal id="modalbasic" size="lg" ref="modalbasic" @hide="close()" v-model="isVisible" :title="this.title"
    :no-close-on-backdrop="true" @ok='close()' @cancel='close()'>
    <messages />
    <b-row>
      <b-col>
        <b-form>
        <!-- Salutation -->
        <b-row>
            <b-col>
            <b-form-group label="Salutation:" label-for="nested-salutation">
                <b-form-input
                id="nested-salutation"
                class="col-sm-10"
                v-model="affiliateContact.salutation"
                placeholder="Salutation"
                >
                </b-form-input>
            </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="Title:" label-for="nested-title">
                <b-form-input
                id="nested-title"
                class="col-sm-10"
                v-model.trim="$v.affiliateContact.title.$model"
                :state="!$v.affiliateContact.title.$error"
                placeholder="Title"
                ></b-form-input>
                <div
                class="text-danger"
                :state="!$v.affiliateContact.title.$error"
                v-if="!$v.affiliateContact.title.maxLength"
                >
                Maximum Characters Allowed 50
                </div>
            </b-form-group>
            </b-col>
        </b-row>
        <!-- End of Salutation -->

        <!--First Name-->
        <b-row>
            <b-col>
            <b-form-group label="First Name:" label-for="nested-firstName">
                <b-form-input
                id="nested-firstName"
                class="col-sm-10"
                v-model.trim="$v.affiliateContact.firstName.$model"
                :state="!$v.affiliateContact.firstName.$error"
                placeholder="First Name"
                ></b-form-input>
                <div
                class="text-danger"
                :state="!$v.affiliateContact.firstName.$error"
                v-if="!$v.affiliateContact.firstName.required && $v.affiliateContact.firstName.$dirty"
                >
                First Name is required
                </div>
                <!-- <div
                class="text-danger"
                :state="!$v.affiliateContact.firstName.$error"
                v-else-if="!$v.affiliateContact.firstName.alpha"
                >
                First Name must be composed only with letters
                </div> -->
                <div
                class="text-danger"
                :state="!$v.affiliateContact.firstName.$error"
                v-else-if="!$v.affiliateContact.firstName.maxLength"
                >
                Maximum Characters Allowed 50
                </div>
            </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="Last Name:" label-for="nested-lastName">
                <b-form-input
                id="nested-lastName"
                class="col-sm-10"
                v-model.trim="$v.affiliateContact.lastName.$model"
                :state="!$v.affiliateContact.lastName.$error"
                placeholder="Last Name"
                ></b-form-input>
                <div
                class="text-danger"
                :state="!$v.affiliateContact.lastName.$error"
                v-if="!$v.affiliateContact.lastName.required && $v.affiliateContact.lastName.$dirty"
                >
                Last Name is required
                </div>
                <!-- <div
                class="text-danger"
                :state="!$v.affiliateContact.lastName.$error"
                v-else-if="!$v.affiliateContact.lastName.alpha"
                >
                Last Name must be composed only with letters
                </div> -->
                <div
                class="text-danger"
                :state="!$v.affiliateContact.lastName.$error"
                v-else-if="!$v.affiliateContact.lastName.maxLength"
                >
                Maximum Characters Allowed 50
                </div>
            </b-form-group>
            </b-col>
        </b-row>
        <!--End of First Name-->

        <!--Phone Number-->
        <b-row>
            <b-col>
            <b-form-group label="Phone Number:" label-for="nested-phonenumber">
                <b-form-input
                id="nested-phonenumber"
                class="col-sm-10"
                v-model.trim="affiliateContact.phoneNumber"
                v-mask="phoneMask"
                placeholder="Phone Number"
                ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="Ext:" label-for="nested-phoneextension">
                <b-form-input
                id="nested-phoneextension"
                class="col-sm-10"
                v-model.trim="affiliateContact.phoneNumberExtension"
                v-mask="extMask"
                placeholder="Ext"
                ></b-form-input>
            </b-form-group>
            </b-col>
        </b-row>
        <!--End of Phone Number-->

        <!--Email-->
        <b-row>
            <b-col>
            <b-form-group label="Email:" label-for="nested-emailaddress">
                <b-form-input
                id="nested-emailaddress"
                class="col-sm-6"
                v-model.trim="$v.affiliateContact.emailAddress.$model"
                :state="!$v.affiliateContact.emailAddress.$error"
                placeholder="Email"
                ></b-form-input>
                <div
                class="text-danger"
                :state="!$v.affiliateContact.emailAddress.$error"
                v-if="!$v.affiliateContact.emailAddress.required && $v.affiliateContact.emailAddress.$dirty"
                >
                Email is required
                </div>
                <div
                class="text-danger"
                :state="!$v.affiliateContact.emailAddress.$error"
                v-else-if="!$v.affiliateContact.emailAddress.email"
                >
                Enter a valid email address
                </div>
                <div
                class="text-danger"
                :state="!$v.affiliateContact.emailAddress.$error"
                v-else-if="!$v.affiliateContact.emailAddress.maxLength"
                >
                Maximum Characters Allowed 50
                </div>
            </b-form-group>
            </b-col>
        </b-row>
        <!--End of Email-->
        <!--Contact Types-->
        <b-row>
              <b-col>
                <b-form-group label="Contact Type(s):" label-for="nested-contacttypes">
                  <multiselect
                    v-model="$v.affiliateContact.contactTypes.$model"
                    :options="contactTypeOptions"
                    group-values="options"
                    group-label="label"
                    track-by="id"
                    label="description"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Contact Type(s)..."
                    style="width: 50%"
                    :class="{ 'multiselect-is-invalid' : $v.affiliateContact.contactTypes.$error }"
                  >
                    <template slot="option" slot-scope="props">
                      <div :title="props.option.tooltip">
                        {{ getOptionLabel(props) }}
                      </div>
                    </template>
                  </multiselect>
                  <div
                    class="text-danger"
                    :state="!$v.affiliateContact.contactTypes.$error"
                    v-if="!$v.affiliateContact.contactTypes.required && $v.affiliateContact.contactTypes.$dirty"
                  >
                    Contact type is required
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Contact Types-->
        <!--Notes-->
        <b-row>
            <b-col>
            <b-form-group label="Notes:" label-for="nested-notes">
                <b-form-textarea
                id="nested-notes"
                class="col-sm-8"
                rows="3"
                v-model.trim="affiliateContact.notes"
                ></b-form-textarea>
            </b-form-group>
            </b-col>
        </b-row>
        <!--End of Notes-->
        </b-form>
      </b-col>
    </b-row>
    <template #modal-footer="{ ok }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="ok">
        Cancel
      </b-button>
      <b-button v-if="!$v.$invalid"
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveAffiliateContact()">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>

<script>
import AffiliateMixin from '../../mixins/AffiliateMixin.vue'
import { validationMixin } from 'vuelidate'
import { mapMutations } from 'vuex'
import PhoneMixin from '../../mixins/PhoneMixin.vue'
import ModalMessages from '../../containers/navs/ModalMessages.vue'
import Multiselect from 'vue-multiselect'
import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'

const {
  required,
  // alpha,
  maxLength,
  email
} = require('vuelidate/lib/validators')

export default {
  name: 'contactModal',
  components: {
    messages: ModalMessages,
    Multiselect
  },

  mixins: [AffiliateMixin, validationMixin, PhoneMixin, CompanyContactMixin],
  validations: {
    affiliateContact: {
      firstName: {
        required,
        // alpha,
        maxLength: maxLength(50)
      },
      lastName: {
        required,
        // alpha,
        maxLength: maxLength(50)
      },
      emailAddress: {
        required,
        email,
        maxLength: maxLength(50)
      },
      contactTypes: {
        required
      },
      title: {
        maxLength: maxLength(50)
      }
    }
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    contactID: 0
  }),
  async created () {
    await this.getContactTypeOptions()
  },
  methods: {
    ...mapMutations([
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      if (opts.contactID) {
        this.contactID = (opts.contactID)
        await this.getAffiliateContactByContactID(this.contactID)
      }

      if (opts.affiliateID) {
        this.initNewContact(opts.affiliateID)
      }

      this.isVisible = true
    },

    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.contactID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async saveAffiliateContact () {
      const contact = this.affiliateContact
      contact.phoneNumber !== ''
        ? contact.phoneNumber = this.removePhoneMask(contact.phoneNumber)
        : contact.phoneNumber = null

      if (contact.phoneNumberExtension === '') {
        contact.phoneNumberExtension = null
      }

      if (this.contactID) {
        await this.updateAffiliateContact(this.affiliateContact)
        this.close()
      } else {
        await this.addAffiliateContact(this.affiliateContact)
        if (this.statusCode === 200) {
          await this.close()
        }
      }
    },
    initNewContact (affiliateID) {
      this.affiliateContact = {
        affiliateID: affiliateID,
        firstName: '',
        lastName: '',
        salutation: '',
        title: '',
        phoneNumber: null,
        phoneNumberExtension: null,
        emailAddress: '',
        contactTypes: [],
        notes: null,
        createdByID: 0,
        active: true
      }
    },
    getOptionLabel (props) {
      return props.option.description || props.option.$groupLabel
    }
  }
}
</script>
